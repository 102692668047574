import { ReactElement } from 'react';
import {useDispatch } from 'react-redux';
import { ThunkDispatchType, actions, Leader, AnyNote } from '../../../store';
import { InfoCardFields } from '../../../utils/types';
import InfoCardContainer from '../InfoCardContainer';
import { NoteType } from '../../../constants';


interface ComponentProps {
  currentLeader: Leader;
}

type Props = ComponentProps;

export const LeaderCard = ({ currentLeader}: Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();
  const updateLeader = (
    leader: Partial<Leader> & {
      id: number;
    }, leaderPic: Blob | null, leaderPicName: string | null, passedPicUrl?: string | null
  ) => dispatch(actions.leaders.updateLeader(leader, leaderPic, leaderPicName, passedPicUrl));

  const infoFields: InfoCardFields<Leader>[] = [
    {label: 'Industry', key: 'industry_display'},
    {label: 'Country', key: 'country_code' },
    {label: 'Postal Code', key: 'postal_code'}
  ];

  const handleSave = async (leader: AnyNote) => {
    await updateLeader(leader as Leader, null, '', currentLeader.pic_url);
  };

  return (
    <InfoCardContainer<Leader> 
      note={currentLeader} 
      saveNote={handleSave}
      addMode={false} 
      title='Executive'
      noteType={NoteType.LEADER} 
      infoFields={infoFields} 
      currentLeader={currentLeader}/>
  );
};

export default LeaderCard;